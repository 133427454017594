import React from "react";
import "./bootstrap";
import "../css/app.css";
import "./i18n/i18n";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { Toaster } from "react-hot-toast";
import FlashMessage from "@/components/FlashMessage";
import Footer from "@/components/Footer"; // Import the Footer component
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Header from "@/components/Header";
import { Provider } from "react-redux";
import store from "./store/store";
import { HelmetProvider } from "react-helmet-async";
import { NextUIProvider } from "@nextui-org/react";
import i18next from "i18next";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


const appName =
    window.document.getElementsByTagName("title")[0]?.innerText ||
    "Visit Thassos";

const theme = createTheme({
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {},
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit!important",
                },
            },
        },
    },
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob("./pages/**/*.tsx");
        return pages[`./pages/${name}.tsx`]();
    },
    setup({ el, App, props }) {
        //in initial page load set i18next language

        i18next.changeLanguage(props.initialPage.props.locale);

        const root = createRoot(el);
        root.render(
            <HelmetProvider>
                <NextUIProvider>
                    <ThemeProvider theme={theme}>
                        <Provider store={store}>
                            <CssBaseline />
                            <Toaster
                                toastOptions={{
                                    style: {
                                        zIndex: 999,
                                    },
                                }}
                            />
                            <FlashMessage />
                            <GoogleReCaptchaProvider

                                reCaptchaKey={props.initialPage.props.captcha_key}
                                scriptProps={{
                                    async: false,
                                    defer: false,
                                    appendTo: "head",
                                    nonce: undefined,
                                }}

                            >
                                <App {...props} />
                            </GoogleReCaptchaProvider>
                        </Provider>
                    </ThemeProvider>
                </NextUIProvider>
            </HelmetProvider>,
        );
    },
    progress: {
        color: "#4B5563",
    },
});
