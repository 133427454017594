import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ModalType = {
    latitude: string | null | undefined;
    longitude: string | null
}

const initialState : ModalType = {
    latitude: null,
    longitude: null
};

const geoLocationSlice = createSlice({
    name: "geolocation",
    initialState,
    reducers: {
        latitude: (state, action: PayloadAction<string>) => {
            state.latitude = action.payload;
        },
        longitude: (state, action: PayloadAction<string>) => {
            state.longitude = action.payload;
        }

    },
});

export const { latitude, longitude } =
    geoLocationSlice.actions;
export default geoLocationSlice.reducer;
