import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "@/store/slices/modalSlice";
import planSlice from "./slices/planSlice";
import { useDispatch } from "react-redux";
import programSlice from "./slices/createProgramSlice";
import geoLocationSlice from "./slices/geoLocationSlice";

const store = configureStore({
    reducer: {
        modal: modalReducer,
        plan: planSlice.reducer,
        progress: programSlice.reducer,
        geolocation: geoLocationSlice
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch

export default store;
