import { planActions } from "@/store/slices/planSlice";
import { router } from "@inertiajs/react";
import { useEffect, useState, useLayoutEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

const FlashMessage = () => {

    const dispatch = useDispatch()
    const [flashMessageUuid, setFlashMessageUuid] = useState(null);

    useEffect(() => {

        const handleStart = router.on("start", (e) => {
            dispatch(planActions.setLoading(true));
        });

        const handleFinish = router.on("finish", (e) => {
            dispatch(planActions.setLoading(false));
        });

        const handleSuccess = router.on("success", (event) => {

            const message = event.detail.page.props.message;

            if (message && (flashMessageUuid == null || message.uuid != flashMessageUuid)) {
                setFlashMessageUuid(message.uuid);

                //on success could me some errors also like member cant make many events
                message?.type === "error"
                    ? toast.error(message.data)
                    : toast.success(message.data);
            }
        });

        //Mostly validation errors
        const handleError = router.on("error", (event) => {
            const errors = event.detail.errors;
            const errorMessages = Object.values(errors);

            if (errorMessages.length > 0) {
                // Display each error message on a separate line
                errorMessages.forEach((errorMessage) => {
                    toast.error(errorMessage);
                });
            } else {
                // Fallback error message if no errors are found
                toast.error("An unknown error occurred");
            }
        });

        // Clean up the listener when the component unmounts
        return () => {
            handleSuccess();
            handleError();
            handleStart();
            handleFinish();
        };
    }, [flashMessageUuid]);
    return null;
};

export default FlashMessage;
